import "./App.css";
import Home from "./Home";
import Error from "./Error";
import AironeSignIn from "./AironeSignIn";
import MateSignIn from "./MateSignIn";
import SmartSignIn from "./navienSmart/SmartSignIn";
import SmartSignInPre from "./navienSmart/SmartSignInPre";
import SmartSignInLGThinQ from "./navienSmart/SmartSignInLGThinQ";
import { Route } from "react-router-dom";
import React from "react";

function App() {
  return (
    <div className="container-login100">
      <Route path="/" component={Home} exact />
      <Route path="/airone" component={AironeSignIn} />
      <Route path="/mate" component={MateSignIn} />
      <Route path="/naviensmart-smartthings" component={SmartSignIn} />
      <Route path="/naviensmart-gva" component={SmartSignInPre} />
      <Route path="/naviensmart-lg" component = {SmartSignInLGThinQ} />
      <Route path="/error" component={Error} />
    </div>
  );
}

export default App;
